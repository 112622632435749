<!--
 * @Author       : JiangChao
 * @Date         : 2023-03-27 13:48:32
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-04-03 22:25:12
 * @Description  : 取消綁定
-->
<template>
    <div class="container"  :style="{ minHeight: boxheight }" >
        <div class="bg"  style="        display: block;">
            <div class="title"><span>取消綁定</span></div>
            <div class="card">
                <van-cell title="" v-if="userBindType === 'credit'">
                    <template #icon>
                        <van-image
                            width="70px"
                            height="40px"
                            :src="visaPayIcon"
                        />
                        <van-image
                            width="70px"
                            height="40px"
                            :src="mastercardPayIcon"
                        />
                    </template>
                </van-cell>
                <van-cell title="" v-if="userBindType === 'line'">
                    <template #icon>
                        <van-image
                            width="100px"
                            height="30px"
                            :src="linePayIcon"
                        />
                    </template>
                </van-cell>
                <van-cell
                    v-if="userBindType === 'credit'"
                    title="信用卡不支持金融信用卡"
                    style="text-align: left"
                >
                </van-cell>
            </div>

            <div class="read-moudle">
                <span class="clause"
                    >說明：<br />取消綁定後，需要重新綁定支付方式，即可以使用</span
                >
            </div>
            <div class="login-way">
                <div class="sms-way" @click="confirm">取消綁定</div>
            </div>
        </div>
    </div>
</template>

<script>
import { handleErr } from "@/helpers";
import _ from "lodash";
import { Toast } from "vant";

export default {
    name: "",
    data() {
        return {
            mastercardPayIcon: require("@/assets/imgs/png/mastercard_pay.png"),
            visaPayIcon: require("@/assets/imgs/png/visa_pay.png"),
            radio: "",
            activeIcon: require("@/assets/imgs/png/icon_agree_selected_circle.png"),
            inactiveIcon: require("@/assets/imgs/png/icon_login_default_circle.png"),
            iconContentYajin: require("@/assets/imgs/png/icon_content_yajin.png"),
            applePayIcon: require("@/assets/imgs/png/apple_pay.png"),
            jkIcon: require("@/assets/imgs/png/img_pay.png"),
            googlePayIcon: require("@/assets/imgs/png/google_pay.png"),
            linePayIcon: require("@/assets/imgs/png/line_pay.jpg"),
            pxIcon: require("@/assets/imgs/png/ic_px.jpg"),
            // mastercardPayIcon: require("@/assets/imgs/png/mastercard_pay.png"),
            // visaPayIcon: require("@/assets/imgs/png/visa_pay.png"),
            // subscriptionIcon: require("@/assets/imgs/png/icon_subscription.png"),
            activeClauseIcon: require("@/assets/imgs/png/icon_agree_selected.png"),
            inactiveClauseIcon: require("@/assets/imgs/png/icon_login_default.png"),
            userId: "",
            token: "",
            userBindType: "",
        };
    },
    methods: {
        confirm: _.throttle(function () {
            this.cancelBind();
        }, 2000),

        $_href(page) {
            if (!page) return;
            if (page.indexOf("https") !== -1) {
                window.location.href = page;
            } else {
                this.$router.push(page);
            }
        },

        async cancelBind() {
            try {
                const res = await this.$axios.get("tapPay/cancelCreditCard");
                console.log("res: ", res);
                if (res.data.success && res.data.statusCode == 200) {
                    Toast("取消綁定成功");
                    this.$router.push("map");
                } else {
                    Toast("取消綁定失敗，" + res.data.msg);
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
        async getUserInfo() {
            try {
                const res = await this.$axios.get("customer/user-info");
                console.log("res: ", res);
                if (res.data.success) {
                    this.userBindType = res.data.result.userBindType;
                }
            } catch (error) {
                console.log(error, "store error");
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    props: {},
    components: {},
    computed: {
        boxheight() {
            return `${window.innerHeight}px`;
        },
    },
    mounted() {
        // this.token = localStorage.getItem("token");
        this.token = window.$cookies.get("token");
        if (this.token) {
            this.$axios.defaults.headers.common["token"] = this.token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
        this.getUserInfo();
    },
    created() {},
};
</script>

<style scoped lang="less">
.read-moudle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    .clause {
        font-size: 12px;
        font-family: SourceHanSansCN, SourceHanSansCN-Regular;
        font-weight: 400;
        text-align: left;
        line-height: 12px;
        margin-left: 10px;
    }
}
.title {
    background: #fee26c;
    text-align: center;
    padding: 10px;

}
.bg {
    text-align: center;
// padding: 0px 20px;


    .logo {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background: #ffffff;
        margin-top: 20px;
        border-radius: 10px;
        line-height: 50px;
    }
    .card {
        border-radius: 10px;
        margin-top: 20px;
        margin: 20px;
        // padding: 0px 20px;
        width:90%
     
    }
    .login-way {
        .sms-way {
            background: #2c394e;
            border-radius: 25px;
            height: 50px;
            color: #ffffff;
            line-height: 50px;
            margin: 20px;
            text-align: center;
        }
    }
    .tip {
        position: fixed;
        bottom: 0;
        text-align: left;
        margin-bottom: 30px;
        p {
            font-size: 14px;
        }
    }

    .login-way {
        .sms-way {
            background: #2c394e;
            border-radius: 25px;
            height: 50px;
            color: #ffffff;
            line-height: 50px;
            margin: 20px;
            text-align: center;
        }
    }
}
</style>
